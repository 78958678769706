import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import LuArticleHero from '../components/lu-article-hero';
import DefaultLayout from '../layouts/default';
import heroImage from '../images/brands-hero.jpg';
import LuArticleToc from '../components/lu-article-toc';
import article1Image from '../images/brands-1.jpg';
import article2Image from '../images/brands-2.jpg';
import article3Image from '../images/brands-3.jpg';
import article4Image from '../images/brands-4.jpg';
import LuArticle, {ViewModel as ArticleViewModel} from '../components/article';
import {useTranslation} from 'react-i18next';

const BrandsPage = ({data, location}: PageProps) => {
  const {t} = useTranslation('brands');
  const PAGE_DATA = {
    title: ['Subsidiary', 'Corporation'],
    heroImage: heroImage,
    subTitle: t('旗下品牌'),
    tabs: [
      {
        title: t('联丰针织'),
        anchor: 'brands-1',
      },
      {
        title: t('恒益蕾丝'),
        anchor: 'brands-2',
      },
      {
        title: t('联丰染整'),
        anchor: 'brands-3',
      },
      {
        title: t('联丰检测'),
        anchor: 'brands-4',
      },
    ],
  };
  const ARTICLE_BODY_1: ArticleViewModel = {
    body: [
      {
        type: 'rich-text',
        html: (
          <div id="brands-1">
            <h3>
              {t('联丰针织')}
              <span className="float-right extra">
                {t('塑造经典·始终如一')}
              </span>
            </h3>
            <p>
              {t(
                '联丰针织是新联和集团的经典品牌，始终致力于运动系列、功能性布料研发与生产。进入市场已经超过30年，积累了丰富的行业经验、获得众多国内外多间知名品牌认可。从品牌成立之初到现在，联丰针织一直不断提升自己，成为专门生产高难度布料的棉布供应商，并且获得国际知名原料供应商产品独家使用权。'
              )}
            </p>
            <p>
              {t(
                '与客户并肩，共同解决作业难题，让客户把心思放在自己专长上，全无后顾之忧。这是联丰针织一直致力于为客户提供有价值的产品与服务的最有力证明。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: `image-group`,
        images: [article1Image],
      },
    ],
  };
  const ARTICLE_BODY_2: ArticleViewModel = {
    bgTheme: 'primary',
    body: [
      {
        type: 'rich-text',
        html: (
          <div id="brands-2">
            <h3>
              {t('恒益蕾丝')}
              <span className="float-right extra">
                {t('推动潮流·品质当先')}
              </span>
            </h3>
            <p>
              {t(
                '恒益蕾丝是新联和集团旗下最新晋的品牌项目，也是最有开创力的品牌。全心全意致力于内衣、家居、休闲服等蕾丝副料研发、生产。依托于集团的完善的的产业链，把握时尚，专注研发，精心生产，并使整个研发生产环节可控、稳定。所以，从进入市场到现在，品质一直在同行业中遥遥领先。6年内，就获得国内外内衣，服装品牌的普遍认可。'
              )}
            </p>
            <p>
              {t(
                '提供一站式的销售服务，注重美学，让恒益蕾丝成为花边的百货公司，品类丰富，品质优秀，服务专业，是恒益持之以恒的品牌信念。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: `image-group`,
        images: [article2Image],
      },
    ],
  };
  const ARTICLE_BODY_3: ArticleViewModel = {
    body: [
      {
        type: 'rich-text',
        html: (
          <div id="brands-3">
            <h3>
              {t('联丰染整')}
              <span className="float-right extra">
                {t('效率与环保并重，专业与创新并举')}
              </span>
            </h3>
            <p>
              {t(
                '联丰染整一直是新联和集团的核心业务之一，专注于棉、化纤类面料及蕾丝等纺织品的高精准度染色及后整理加工。不仅服务于集团内部其他品牌，也为同行业提供高品质的染整服务。生产工厂完整完善，单类产品接近20年专业生产经验，通过国内外多家大型品牌、验证机构审批。业已成为地区名列前茅的染整品牌。'
              )}
            </p>
            <p>
              {t(
                '规模成就效率，效率成就品牌。联丰染整坚持用最高效率的工作方法解决客户产品问题，并同时注重地区环境保护。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: `image-group`,
        images: [article3Image],
      },
    ],
  };
  const ARTICLE_BODY_4: ArticleViewModel = {
    bgTheme: 'primary',
    body: [
      {
        type: 'rich-text',
        html: (
          <div id="brands-4">
            <h3>
              {t('联丰检测')}
              <span className="float-right extra">
                {t('持心公正，专业严谨')}
              </span>
            </h3>
            <p>
              {t(
                '自2010年开始，新联和集团一直努力开拓和完善自身的产品检测能力。并在2016年正式成立技术中心，以最专业的态度，最诚恳的心态进行检测，提供27项物理、化学测试。联丰检测不断引进高精密检测仪器，培养人才，精细业务，成果斐然。各项突破性进展，为整个集团的产品研发和质量把控提供了强有力的支持！'
              )}
            </p>
            <p>
              {t(
                '业精于勤，事成于谨。业务精熟严谨，持心公平公正效率公平，这一切让联丰检测能够在坚持专业与公平的前提下，提供高效能的检测服务。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: `image-group`,
        images: [article4Image],
      },
    ],
  };
  return (
    <DefaultLayout location={location}>
      <LuArticleHero
        bgImageUrl={PAGE_DATA.heroImage}
        title={PAGE_DATA.title}
        subTitle={PAGE_DATA.subTitle}
      />
      <LuArticleToc tabs={PAGE_DATA.tabs} />
      <LuArticle vm={ARTICLE_BODY_1} />
      <LuArticle vm={ARTICLE_BODY_2} />
      <LuArticle vm={ARTICLE_BODY_3} />
      <LuArticle vm={ARTICLE_BODY_4} />
    </DefaultLayout>
  );
};

export default BrandsPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
