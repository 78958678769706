import clsx from 'clsx';
import React from 'react';
import { ReactNode } from 'react';
import * as styles from './index.module.scss';
import './index.scss';

type SectionRichText = {
  type: 'rich-text';
  html: ReactNode;
};

type SectionRichTextRaw = {
  type: 'rich-text-raw';
  html: string;
};

type SectionImageGroup = {
  type: `image-group`;
  images: string[];
  caption?: string;
};

type SectionCustom = {
  type: 'custom';
  component: ReactNode;
};

export type ViewModel = {
  body: (SectionCustom | SectionImageGroup | SectionRichText | SectionRichTextRaw)[];
  bgTheme?: 'primary';
};

type PropTypes = {
  vm: ViewModel;
};

export default function LuArticle({ vm }: PropTypes) {
  const themeClassName = vm.bgTheme ? styles[`${vm.bgTheme}BgTheme`] : null;
  const articleClassName = clsx(styles.article, themeClassName);
  return (
    <article className={articleClassName}>
      {vm.body.map(section => {
        if (section.type === 'rich-text-raw')
          return (
            <section className={clsx('rich-text', styles.richText)}
              dangerouslySetInnerHTML={{
                __html: section.html
              }} >
            </section>
          );
        if (section.type === 'rich-text')
          return (
            <section className={clsx('rich-text', styles.richText)}>
              {section.html}
            </section>
          );
        if (section.type === 'image-group')
          return (
            <section className={styles.imageSection}>
              <img src={section.images[0]} alt={section.caption} />
              {section.caption && <div className={styles.arrow}></div>}
              {section.caption && (
                <div className={styles.caption}>{section.caption}</div>
              )}
            </section>
          );
        if (section.type === 'custom') return section.component;
        return null;
      })}
    </article>
  );
}
